export const environment = {
  production: false,
};

export const apiQBonoEnvironment = {
  baseUrl: 'https://xunta.api.staging.qaroni.net/v1',
};

export const GoogleMapsEnvironment = {
  key: 'AIzaSyBPdC8GOPlFP7ZY1bQpGSTvXuarRO6SNv4',
};

export const GoogleReCaptchaEnvironment = {
  sitekey: '6LfXi9YlAAAAAIHxyl4jI5E8-TGZZbQhXZykzbpY',
};
